<template>
  <div style="width:100%">
    <PackageList v-if="editType == 'list'"  @changeShowType="changeShowType"></PackageList>
    <PackageForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></PackageForm>
    <PackageInfo v-if="editType == 'show'" :editId="editId"   @changeShowType="changeShowType"></PackageInfo>
  </div>
</template>

<script>
import PackageList from './components/PackageList'
import PackageForm from './components/PackageForm'
import PackageInfo from './components/PackageInfo'
export default {
  name: 'package',
  components: { PackageForm,PackageList,PackageInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>