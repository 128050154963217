<template>
  <div style="width:100%">
      <el-button type="primary" @click="editDetail('', 'add')" size="small">新增</el-button>
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="名称、编号"></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
            <el-date-picker
            v-model="addDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
           >
            </el-date-picker>
        </el-form-item>


        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>



      </el-form>
    </div>
    <el-table :data="list" 
    :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}" 
    row-key="id" stripe 
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="table-text" @click="editDetail(scope.row.id, 'show')">{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="单价（元）" align="center" show-overflow-tooltip prop="unitPrice"></el-table-column> 
      <el-table-column label="优惠价（元）" align="center" show-overflow-tooltip prop="favorablePrice"></el-table-column> 
      <el-table-column label="编号" align="center" show-overflow-tooltip prop="code"></el-table-column> 

      <el-table-column label="创建时间" align="center" show-overflow-tooltip prop="createTime"></el-table-column>  
     
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-edit" @click="editDetail(scope.row.id,'edit')">编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
 
      
  </div>
</template>

<script>
import { Confirm } from '@/utils/common.js'
import { deletePackageInfo, getPackageList } from '@/api/index.js'
export default {
  name: 'capital',
  components: {},
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        startTime:'',
        endTime:''

      },
      list: [],
      total: 0,
      editRow: '',
      editType: '',
      addDate:[]
    }
    
  },
  created() {
    this.getList();
  },
  methods: {
    editDetail(id, editType) {
      this.$emit('changeShowType', editType,id)
    },
    getList() {
      if(this.addDate && this.addDate.length>0){
        this.form.startTime=this.addDate[0];
        this.form.endTime=this.addDate[1];
      }else{
        this.form.startTime='';
        this.form.endTime='';
      }
      getPackageList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('确定删除该商品吗?', () => {
        deletePackageInfo({ ids:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
  }
}
</script>

<style>
</style>