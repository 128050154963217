<template>

  <div style="width:99%;margin:auto;">
    <div class="form-title">套餐详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">基础信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">名称：<span class="col-text">{{form.name}}</span></div>
            <div class="grid-content1">编码：<span class="col-text ">{{form.code}}</span></div>
            <div class="grid-content1">单价：<span class="col-text red">￥{{form.unitPrice}}</span></div>
            <div class="grid-content1">优惠价：<span class="col-text red">￥{{form.favorablePrice}}</span></div>
            <div class="grid-content1">备注：<span class="col-text">{{form.remark}}</span></div>
            <div class="grid-content1">图片：<UploadImg v-model="form.thumbnailList" :limit="1" :disabled="true"></UploadImg></div>
          </div>
        </div>
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">组合信息</h3>
        <div class="basic-info-content">
          <div style="width:780px; text-align:left;margin:auto;">
          <div class="combination-box" v-for="(combinationInfo,index) in form.combinationList" :key="index">
            <div class="box-left">
              <div class="combination-info" >              
                <div class="combination-img" title="点击查看大图"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(combinationInfo.thumbnailList).url"  @click.stop="showBigImage(getFirstFileByList(combinationInfo.thumbnailList).url)"></el-image></div>
                <div class="item" :title="combinationInfo.name">组合名称：{{combinationInfo.name}}</div>
                <div class="item">单价：{{combinationInfo.unitPrice}}元</div>
                <div class="item">优惠价：{{combinationInfo.favorablePrice}}元</div>
              </div>
              <div class="goods-box">
                <div class="goods-info" v-for="goodsInfo in combinationInfo.goodsList" :key="goodsInfo.id">
                  <div class="goods-img"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(goodsInfo.thumbnailList).url"   @click.stop="showBigImage(getFirstFileByList(goodsInfo.thumbnailList).url)"></el-image></div>
                  <div class="item" :title="goodsInfo.name">商品名称：{{goodsInfo.name}}</div>
                  <div class="item">商品单价：{{goodsInfo.unitPrice}}元</div>
                  <div class="item">商品优惠价：{{goodsInfo.favorablePrice}}元</div>
                </div> 

              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  

    </div>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>

    <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />
  </div>


</template>

<script>
import { getPackageInfo } from '@/api/index.js'

import UploadImg from '@/components/UploadImg/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'CustomerForm',
  components: { UploadImg,ElImageViewer },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      form:{
        id:'',
        name:'',
        remark:'',
        code:'',
        unitPrice:'',
        combinationList:[],
        thumbnailList:[],
        favorablePrice:''
      },
      isShowSelectGoodsModal: false,
      imgDialogVisible:false,
      bigImageUrl:''
    }
  },
  created() {
    this.form.id = this.editId;
    this.getPackageInfo();
  },
  mounted() {
    
  },
  methods: {
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    showBigImage(url){
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    onClose() {
      this.imgDialogVisible = false
    },

    
    //编辑获取详情
    getPackageInfo(){
      let param = {id:this.editId};      
      getPackageInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;        
      })
    },
    getFirstFileByList(list){
      if(list && list.length>0){
        return list[0];
      }
      return {};
    },
    
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/info.scss'
</style>

<style scoped>
/**编辑套餐信息中的组合列表样式 */
.combination-box{
  width:870px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.combination-box .box-left{
  flex: 1;
}
.combination-box .box-left .combination-info{
  display: flex;
  border-bottom: 1px solid #C9E3FB;
  line-height: 70px;
  height: 70px;
}
.combination-box .box-left .combination-info .combination-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  margin: auto;
  cursor: pointer;
  padding-left: 5px;
}
.combination-box .box-left .combination-info .item{
  flex: 1;
  text-align: left;
  line-height: 70px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.combination-box .box-left .goods-box{
  padding-left:50px;
}
.combination-box .box-left .goods-box .goods-info{
  display: flex;
  border-left: 1px solid #C9E3FB;
  line-height: 60px;
  height: 60px;
}

.combination-box .box-left .goods-box .goods-info:not(:last-child){
  border-bottom: 1px solid #C9E3FB;
}


.combination-box .box-left .goods-box .goods-info .goods-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  padding-left: 5px;
}
.combination-box .box-left .goods-box .goods-info .item{
  flex: 1;
  text-align: left;
  line-height: 60px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}


</style>
