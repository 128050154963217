<template>
  <div>
    <div class="form-title">{{editType | typeName}}套餐信息</div>
    <el-form size="small" inline ref="form" :model="form" label-width="auto"  >
    <el-collapse v-model="activeNames">
      <el-collapse-item title="基础信息" name="1">
        <div style="text-align:center;">
        <div style="margin:auto;">
          <el-form-item label="名称" prop="name" :rules="getRules('名称不可为空')">
            <el-input v-model="form.name" placeholder="请输入名称" style="width:300px" :disabled="disabled"></el-input>
          </el-form-item>     
           <el-form-item label="编码" prop="code" :rules="getRules('请输入编码')"  >
              <el-input v-model="form.code" maxlength="50" placeholder="请输入编码" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>     
          
        </div>
        <div style="margin:auto;">
         <el-form-item label="单价" prop="unitPrice" :rules="getRules('单价不能为空')"  >
              <el-input v-model="form.unitPrice" type="number" placeholder="请输入单价" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
          <el-form-item label="优惠价" prop="favorablePrice" :rules="getRules('优惠价不能为空')" >
              <el-input v-model="form.favorablePrice" type="number" placeholder="请输入优惠价" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>
          
        </div>

        <div style="margin:auto;">     
          <el-form-item label="备注" prop="code"  >
              <el-input v-model="form.remark" type="textarea" maxlength="200" placeholder="请输入备注" :disabled="disabled" style="width:300px"></el-input>
          </el-form-item>     
          <el-form-item label="图片" prop="thumbnailList" :rules="getRules('请选择图片')"  >
            <UploadImg v-model="form.thumbnailList" :limit="1" :disabled="disabled" style="width:300px"></UploadImg>
          </el-form-item>
        </div>

        </div>

        
      </el-collapse-item>

      <el-collapse-item title="组合信息" name="2">

        <div style="text-align:center">
          <el-button type="primary" icon="el-icon-search" v-if="editType!='show'" @click="showSelectGoods(true)">选择组合</el-button>
        </div>
        <div style="width:780px; text-align:left;margin:auto;">
          <div class="combination-box" v-for="(combinationInfo,index) in form.combinationList" :key="combinationInfo.id">
            <div class="box-left">
              <div class="combination-info" >              
                <div class="combination-img" title="点击查看大图"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(combinationInfo.thumbnailList).url"  @click.stop="showBigImage(getFirstFileByList(combinationInfo.thumbnailList).url)"></el-image></div>
                <div class="item" :title="combinationInfo.name">组合名称：{{combinationInfo.name}}</div>
                <div class="item">单价：{{combinationInfo.unitPrice}}元</div>
                <div class="item">优惠价：{{combinationInfo.favorablePrice}}元</div>
              </div>
              <div class="goods-box">
                <div class="goods-info" v-for="goodsInfo in combinationInfo.goodsList" :key="goodsInfo.id">
                  <div class="goods-img"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(goodsInfo.thumbnailList).url"   @click.stop="showBigImage(getFirstFileByList(goodsInfo.thumbnailList).url)"></el-image></div>
                  <div class="item" :title="goodsInfo.name">商品名称：{{goodsInfo.name}}</div>
                  <div class="item">商品单价：{{goodsInfo.unitPrice}}元</div>
                  <div class="item">商品优惠价：{{goodsInfo.favorablePrice || goodsInfo.unitPrice}}元</div>
                </div> 

              </div>
            </div>
            <div class="box-right">
              <el-button type="danger" v-if="editType!='show'" icon="el-icon-delete" circle @click="removeGoods(index)"></el-button>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" v-debounce="submit" v-if="editType!='show'" >保存</el-button>
    </div>

    </el-form>

    <ChooseCombination v-if="isShowSelectGoodsModal" v-model="isShowSelectGoodsModal" @okSelectedCombinationInfoList="okSelectedCombinationInfoList" ></ChooseCombination>

    <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />


  </div>
</template>

<script>
import { addPackageInfo, updatePackageInfo,getPackageInfo } from '@/api/index.js'

import UploadImg from '@/components/UploadImg/index.vue'
import ChooseCombination from '@/components/ChooseCombination/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  name: 'CustomerForm',
  components: { UploadImg,ChooseCombination,ElImageViewer },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2'],
      form:{
        id:'',
        name:'',
        remark:'',
        code:'',
        unitPrice:'',
        combinationList:[],
        thumbnailList:[],
        favorablePrice:''
      },
      disabled:false,
      isShowSelectGoodsModal: false,
      imgDialogVisible:false,
      bigImageUrl:''
    }
  },
  created() {
    this.disabled = this.editType=='show';
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getPackageInfo();
    }
  },
  mounted() {
    
  },
  methods: {
    getRules(message){
      if(this.disabled){
        return [];
      }
      return [{ required: true, message: message, trigger: 'blur' }];
    },
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    showBigImage(url){
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    onClose() {
      this.imgDialogVisible = false
    },
    removeGoods(index){
      this.form.combinationList.splice(index,1)
      this.handlePackagePrice();
    },
    okSelectedCombinationInfoList(combinationList){      
      console.log('选择组合',combinationList)
      //以下为去重处理
      let allList = this.form.combinationList.concat(combinationList);

      let newList = [];
      for(let item1 of allList){
        let flag = true;
        for(let item2 of newList){
          if(item1.originalId == item2.originalId){
            flag=false;
          }
        }
        if(flag){
          newList.push(item1)
        }
      }
      this.form.combinationList=newList;
      this.isShowSelectGoodsModal = false;
      this.handlePackagePrice();
    },
    handlePackagePrice(){
      let price = 0;
      let favorablePrice =0;
      for(let goods of this.form.combinationList){
        price+=goods.unitPrice;
        favorablePrice+=goods.favorablePrice;

      }
      this.form.unitPrice = price
      this.form.favorablePrice = favorablePrice

    },
    showSelectGoods(type){
      this.isShowSelectGoodsModal = type;
    },
    //编辑获取详情
    getPackageInfo(){
      let param = {id:this.editId};      
      getPackageInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;        
      })
    },
    getFirstFileByList(list){
      if(list && list.length>0){
        return list[0];
      }
      return {};
    },
    submit() {
      
     if (!this.$validate('form')) return
     if(this.form.combinationList.length==0){
        this.$message.error('请选择商品')
        return;
      }
      let form = JSON.parse(JSON.stringify(this.form));
      let success = res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '操作失败')
          return
        }
        this.$message.success('操作成功')
        this.cancel()
      }
      if (this.editType == 'add') {
        addPackageInfo(form).then(success)
      } else {
        updatePackageInfo(form).then(success)
      }
    },
  },
}
</script>

<style scoped>
/**编辑套餐信息中的组合列表样式 */
.combination-box{
  width:870px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.combination-box .box-left{
  flex: 0 0 800px;
  border-right: 1px solid #C9E3FB;
}
.combination-box .box-left .combination-info{
  display: flex;
  border-bottom: 1px solid #C9E3FB;
  line-height: 70px;
  height: 70px;
}
.combination-box .box-left .combination-info .combination-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  margin: auto;
  cursor: pointer;
  padding-left: 5px;
}
.combination-box .box-left .combination-info .item{
  flex: 1;
  text-align: left;
  line-height: 70px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.combination-box .box-left .goods-box{
  padding-left:50px;
}
.combination-box .box-left .goods-box .goods-info{
  display: flex;
  border-left: 1px solid #C9E3FB;
  line-height: 60px;
  height: 60px;
}

.combination-box .box-left .goods-box .goods-info:not(:last-child){
  border-bottom: 1px solid #C9E3FB;
}


.combination-box .box-left .goods-box .goods-info .goods-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  padding-left: 5px;
}
.combination-box .box-left .goods-box .goods-info .item{
  flex: 1;
  text-align: left;
  line-height: 60px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}


.combination-box .box-right{
  flex: 1;
  text-align: center;
  padding-top:5px;
}
</style>
